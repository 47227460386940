import Style from "../assets/styles/home.module.scss";
import Header from "./parts/header";
import Footer from "./parts/footer";

const HomeLayout = ({children}) => {
    return (
        <>
            <Header/>
                <main className={Style.HomeContainer}>
                    {children}
                </main>
            <Footer/>
        </>
    )
}

export default HomeLayout;