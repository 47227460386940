import {useSearchParams} from "react-router-dom";
import HomeLayout from "../../template/home";

const Search = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const q = searchParams.get("q");
    console.log(q)

    return (
        <HomeLayout>{q}</HomeLayout>
    )
}

export default Search;