import { getMessaging, getToken } from "firebase/messaging";
import firebaseApp from "../firebase-config";

const messaging = getMessaging(firebaseApp);

export const requestPermission = async () => {
    const vapidKey = "BAMS6GIzcBgzMWwZwYeWx9WAbLEGCxWl_Abj65AtcxTJye4NZKFwET1OMqC0wH53UHLsLk46gKvUuucAufEpm90";
    try {
        const token = await getToken(messaging, { vapidKey });
        console.log("FCM Token:", token);
        return token;
    } catch (error) {
        console.error("Permission Denied:", error);
        return null;
    }
};