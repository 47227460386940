import Style from "../assets/styles/home.module.scss";
import {Link} from "react-router-dom";
import {urlDasher} from "../api/helper";
import {useSelector} from "react-redux";

const Categories = ({
                        home,
                        province,
                        category,
                        subcat,
                        subsubcat,
                        categories,
                        subCategories,
                        subSubCategories,
                    }) => {
    const selectedProvince = useSelector((state) => state?.user?.province) || "ایران";

    const renderSubSubCategories = () =>
        subSubCategories?.map((subSubCat, index) => (
            <li key={index}>
                <Link
                    to={`/browse/${urlDasher(selectedProvince)}/${urlDasher(category)}/${urlDasher(subcat)}/${urlDasher(subSubCat.name)}`}
                >
                    <i className="icon" data-subsub-cat-id={subSubCat.id}></i>
                    <span>{subSubCat.name}</span>
                </Link>
            </li>
        ));

    const renderSubCategories = () =>
        subCategories?.map((subCat, index) => (
            <li key={index}>
                <Link
                    to={`/browse/${urlDasher(selectedProvince)}/${urlDasher(category)}/${urlDasher(subCat.name)}`}
                >
                    <i className="icon" data-sub-cat-id={subCat.id}></i>
                    <span>{subCat.name}</span>
                </Link>
            </li>
        ));

    const renderCategories = () =>
        categories?.map((category, index) => (
            <li key={index}>
                <Link to={`/browse/${selectedProvince}/${urlDasher(category.name)}`}>
                    <i className="icon" data-cat-id={category.id}></i>
                    <span>{category.name}</span>
                </Link>
            </li>
        ));

    return (
        categories?.length || subCategories?.length || subSubCategories?.length ?
            <ul className={`${Style.HomeCategories} list-unstyled d-flex`}>
                {subsubcat
                    ? null
                    : subcat
                        ? renderSubSubCategories()
                        : category
                            ? renderSubCategories()
                            : (province || home) && renderCategories()}
            </ul> :
            <ul className={Style.CatLoading}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
    );
};

export default Categories;