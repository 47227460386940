export const AdsPrice = (price, pricetype) => {
    if(price && pricetype === 1){
        return <span>تماس بگیرید</span>;
    } else if(price && pricetype === 2) {
        return <span>{formatPrice(price)} <bdi>تومان</bdi></span>;
    } else {
        return <span>تماس بگیرید</span>;
    }
}

export const formatPrice = (p) => {
    return p?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const urlDasher = (str) => {
    return str ? str.replace(/\s+/g,'-') : null;
}

export const unDasher = (str) => {
    return str ? decodeURIComponent(str).replace(/-/g,' ') : null;
}

export const AdsType = (type) => {
    if(type === 1){
        return {label: 'نو', value: type};
    } else if(type === 2) {
        return {label: 'کارکرده', value: type};
    } else if(type === 3) {
        return {label: 'خدماتی', value: type};
    }
}

export const AdsExchange = (ex) => {
    if (ex === 1) {
        return "قابل معاوضه";
    } else {
        return "بدون معاوضه";
    }
}

export const AdsEstateOptions = () => {
    let options = [
        {label: 'پارکینگ', value: '1', icon: 'las la-parking'},
        {label: 'انباری', value: '2', icon: 'las la-box'},
        {label: 'آسانسور', value: '3', icon: 'las la-database'}
    ];

    return options;
}

export const AdsCarColor = (filter, id) => {
    let color = [
        {label: 'سفید', value: '1', color: '#fff'},
        {label: 'مشکی', value: '2', color: '#000'},
        {label: 'نقره ای', value: '3', color: '#C0C0C0'},
        {label: 'نوک مدادی', value: '4', color: '#2e2e2e'},
        {label: 'خاکستری', value: '5', color: '#808080'},
        {label: 'بژ', value: '6', color: '#F5F5DC'},
        {label: 'قهوه ای', value: '7', color: '#8B4513'},
        {label: 'یشمی', value: '8', color: '#006400'},
        {label: 'سرمه ای', value: '9', color: '#00008B'},
        {label: 'آلبالویی', value: '10', color: '#DC143C'},
        {label: 'قرمز', value: '11', color: '#FF0000'},
        {label: 'آبی', value: '12', color: '#1E90FF'},
        {label: 'زرد', value: '13', color: '#FFFF00'},
        {label: 'بادمجانی', value: '14', color: '#4B0082'},
        {label: 'نارنجی', value: '15', color: '#FFA500'},
        {label: 'سبز', value: '16', color: '#008000'},
    ];

    if(filter){
        return color.find(item => item.value === id);
    } else {
        return color;
    }
}

export const AdsCarYear = (filter, id) => {
    let year = [];
    let i,j = 0;

    for(i=1403,j=2024;i>=1370,j>=1991;i--,j--){
        year.push({label: `${i} شمسی /${j} میلادی `, value: `${i},${j}`})
    }

    if(filter){
        return year.find(item => item.value === id);
    } else {
        return year;
    }
}

export const urlPluser = (str) => {
    return str ? str.trim().replace(/\s+/g,'+') : null;
}