import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAjP7emf3xBYJQWjNfT5ZfN4Xcff84U4yY",
    authDomain: "nobazaarapi-1538502060412.firebaseapp.com",
    databaseURL: "https://nobazaarapi-1538502060412.firebaseio.com",
    projectId: "nobazaarapi-1538502060412",
    storageBucket: "nobazaarapi-1538502060412.firebasestorage.app",
    messagingSenderId: "142939114920",
    appId: "1:142939114920:web:ea1542fc18d98fcd38a19d"
};

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;